// bootstrap
@import"~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~bootstrap/dist/css/bootstrap.css";

@import '~ngx-toastr/toastr';

@import "~font-awesome/css/font-awesome.css";
// /* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

// bootstrap fix for ui
@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 80%;
  }
}

// font-awesome
@import "~font-awesome/css/font-awesome.css";

// ngx-owl-carousel
@import "~ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";

// font Century Gothic
// regular
@font-face {
  font-family: "Century Gothic";
  src: url("./assets/fonts/CenturyGothic/GOTHIC.TTF");
}
// bold
@font-face {
  font-family: "Century Gothic";
  font-weight: bold;
  src: url("./assets/fonts/CenturyGothic/GOTHICB.TTF");
}
// bold italic
@font-face {
  font-family: "Century Gothic";
  font-weight: bold;
  font-style: italic;
  src: url("./assets/fonts/CenturyGothic/GOTHICBI.TTF");
}
// italic
@font-face {
  font-family: "Century Gothic";
  font-style: italic;
  src: url("./assets/fonts/CenturyGothic/GOTHICI.TTF");
}

// Font Cairo
// regular
@font-face {
  font-family: "Cairo";
  src: url("./assets/fonts/cairo-font/Cairo-Regular-400.ttf");
}
// bold
@font-face {
  font-family: "Cairo";
  font-weight: bold;
  src: url("./assets/fonts/cairo-font/Cairo-Bold-700.ttf");
}

// general
* {
  box-sizing: border-box;
}

.custom-container {
  width: 80%;
  margin: auto;
}
html {
  scroll-behavior: smooth;
}

body {
  font-family: "Century Gothic", "Cairo", Courier, monospace;
  background: #e9e9e9;
  // background: #fff;
  color: #888888;
  text-transform: capitalize;
}


hr{
  border-top: 2px solid #fff;
}

// active route link
.link-active{
  color: #25d666!important;
}

// forms validation styles
.form-control.is-invalid {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.1875rem) center !important;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}
.form-control.is-valid {
  border-color: #28a745 !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.1875rem) center !important;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}

// owl-carousel customize
.hero-wrapper {
  .owl-theme .owl-nav.disabled + .owl-dots {
    margin: 0;
    position: absolute;
    bottom: 15%;
    right: 8%;
  }
  .owl-theme .owl-dots .owl-dot span {
    height: 20px;
    width: 20px;
    background: #E9E9E960;
  }
  .owl-theme .owl-dots .owl-dot.active span,
  .owl-theme .owl-dots .owl-dot:hover span {
    background: #fff;
  }

  @media only screen and (max-width: 768px){
    .owl-theme .owl-nav.disabled + .owl-dots {
      bottom: 150px;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

  }
  @media only screen and (max-width: 576px){
    .owl-theme .owl-nav.disabled + .owl-dots {
      bottom: 100px;
    }
    .owl-theme .owl-dots .owl-dot span {
      height: 10px ;
      width: 10px ;
    }
  }
}

.clients-wrapper{
    // owl-carousel customize
    .owl-theme .owl-nav.disabled + .owl-dots {
      margin-top: 50px;
    }
    .owl-theme .owl-dots .owl-dot span {
      height: 20px;
      width: 20px;
      background: #fff;
    }
    .owl-theme .owl-dots .owl-dot.active span,
    .owl-theme .owl-dots .owl-dot:hover span {
      background: #25d666;
    }

    @media only screen and (max-width: 576px){
      .owl-theme .owl-dots .owl-dot span {
        height: 10px ;
        width: 10px ;
      }
    }
}
#morocco{
  .clients-wrapper{

    .owl-theme .owl-dots .owl-dot span {
      height: 15px;
      width: 15px;
      background: #f3f3f3;
    }
    .owl-theme .owl-dots .owl-dot.active span,
    .owl-theme .owl-dots .owl-dot:hover span {
      background: #25d666;
    }

    @media only screen and (max-width: 576px){
      .owl-theme .owl-dots .owl-dot span {
        height: 10px ;
        width: 10px ;
      }
    }
}
}

.products-list-wrapper {
  // angular materials components customization
  .mat-menu-panel{
    padding: 7px 15px;
    border-radius: 15px !important;
    border: 1px solid #25d666;
  }
  .mat-expansion-panel-header{
    padding-left: 0;
  }
  .mat-expansion-panel:not([class*=mat-elevation-z]) {
    box-shadow: none;
  }
}

.product-details-wrapper{
  .owl-theme .owl-nav.disabled + .owl-dots{
    visibility: hidden;
  }
}


// ngx-slider customization
// used in price filtering
.ngx-slider .ngx-slider-pointer {
  width: 20px !important;
  height: 20px !important;
  top: -8px !important;
  background-color: #25d666 !important;
  border-radius: 50% !important;
}
.ngx-slider .ngx-slider-pointer:after {
  display: none !important;
}
.ngx-slider.animate .ngx-slider-bubble.ngx-slider-combined{
  left: 0 !important;
}
.ngx-slider .ngx-slider-selection {
  background: #25d666 !important;
}


/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #25d666;
  border-radius: 5px;
  opacity: 0.7;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #25d666;
}

// mobile font sizes
@media only screen and (min-width: 1440px) {
  h2 {
    font-size: 40px  ;
  }
  h3{
    font-size: 32px   ;
  }
  h4{
    font-size: 24px   ;
  }
  h5{
    font-size: 20px   ;
  }
  p, a, small, input, label, li{
    font-size: 18px   ;
  }

}

// mobile font sizes
@media only screen and (max-width: 1440px) {
  h2 {
    font-size: 30px  ;
  }
  h3{
    font-size: 22px   ;
  }
  h4{
    font-size: 18px   ;
  }
  h5{
    font-size: 16px   ;
  }
  p, a, small, input{
    font-size: 14px   ;
  }
}

// mobile font sizes
@media only screen and (max-width: 1200px) {
  h2 {
    font-size: 22px  ;
  }
  h3{
    font-size: 18px   ;
  }
  h4{
    font-size: 16px   ;
  }
  h5{
    font-size: 14px   ;
  }
  p, a, small, input, li{
    font-size: 12px   ;
  }

}

// mobile font sizes
@media only screen and (max-width: 576px) {
  h2 {
    font-size: 20px  ;
  }
  h3{
    font-size: 18px   ;
  }
  h4{
    font-size: 16px   ;
  }
  h5{
    font-size: 14px   ;
  }
  p, a, small, input, li{
    font-size: 12px   ;
  }
}

.ngx-spinner-overlay{
  img {
    animation: beat 1s infinite alternate ease-in-out;
    transform-origin: center;
  }

  /* Heart beat animation */
  @keyframes beat{
    to { transform: scale(0.8); }
  }
}

.not-fount{
  overflow: hidden;
  img{
    width: 100%;
    max-height: 600px;
  }
  .text-uppercase{
    color: #25d666;
    font-weight: 700;
  }
}
.no-products{
  max-height: 300px;
  img{
    max-height: 250px;
    width: auto;
  }
}
.title-color{
  color: #25d666;
}
.invalid-feedback{
  display: block !important;
}
.valid-feedback{
  display: block !important;
}

.link{
  color: #888888;
  font-weight: 700;
  text-decoration: none !important;
  &:hover{
    color: #25d666;
  }
}

.form-control-lg{
  font-size: 1rem;
}


